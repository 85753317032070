import { createSlice } from '@reduxjs/toolkit';

export const homepageSlice = createSlice({
  name: 'homepage',
  initialState: {
    todayBestDeals: [],
    featuredProducts: [],
    popularProducts: [],
    homePageProducts: []
  },
  reducers: {
    setTodayBestDeals: (state, action) => {
      state.todayBestDeals = action.payload;
    },  
    setFeaturedSavings: (state, action) => {
        state.featuredProducts = action.payload;
    },  
    setPopularProducts: (state, action) => {
        state.popularProducts = action.payload;
    },
    setHomePageProducts: (state, action) => {
      state.homePageProducts = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setTodayBestDeals , setFeaturedSavings , setPopularProducts, setHomePageProducts  } = homepageSlice.actions;

export default homepageSlice.reducer;
