import { useEffect, useState } from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";

import Carousel from "../components/carousel";
import CategoryCard from "../components/category-card.component";
import categories from "../statics/categories";
import Loader from "../components/Loader";
import api from "../statics/api.js"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ProductVertical from "../components/product-vertical.component";
import ProductHorizontal from "../components/product-horizontal.component.jsx";
import Footer from "../components/footer.component.jsx";
import { set_username } from "../reducers/username";
import { set_cart } from "../reducers/cart";

import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../components/search-input.component.jsx";
import Overlay from "../components/Overlay.jsx";
import BottomNav from "../components/bottom-nav.component.jsx";
import Cart from "../components/cart.compontent.jsx";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import MobileMenu from "../components/menu.component.jsx";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


function HomePage() {
    // let slides = [
    //     "https://wallpapercave.com/wp/wp3386769.jpg",
    //     "https://wallpaperaccess.com/full/809523.jpg",
    //     "https://getwallpapers.com/wallpaper/full/5/c/0/606489.jpg",
    //   ];
    const [slides, setSlides] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    // const [todatBestDeals, setTodayBestDeals] = useState([]);
    // const [featuredSavings, setFeaturedSavings] = useState([]);
    // const [popularProducts, setPopularProducts] = useState([]);
    const [homePageProducts, setHomePageProducts] = useState([]);
    const dispatch = useDispatch();
    const data = useSelector((state) => state);

    const getCookie = (cookieName) => {
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1);
            }
        }
        // If cookie not found, return null
        return false;
    }

    const handleRequest = async () => {
        let postData = new FormData();
        const session = getCookie("session");
        if (session) {

            postData.append('session', session);
        } else {
        }
        for (let i = 0; i < 100; i++) {
            try {

                let response = await fetch(`${api}first_load.php`, {
                    method: 'POST',
                    body: postData
                });
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);

                // setTodayBestDeals(responseData["today_best_deals"]);
                // setFeaturedSavings(responseData["featured_savings"]);
                // setPopularProducts(responseData["popular_products"]);
                if (responseData.res.state) {
                    if (responseData.res.msg === "New session") {
                        const expiryDate = new Date(responseData.exp);
                        document.cookie = `session=${responseData.new_session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
                        localStorage.setItem("fullname", responseData.res.username);
                        dispatch(set_username(localStorage.getItem("fullname")));
                    }
                    dispatch(set_cart(responseData.cart))
                    console.log(responseData)
                    return
                } else {
                    // Delete Session
                    toast.error("Please login again.")
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                    window.location.reload();
                }

                return
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
        throw new Error('Sign up failed. Please try again.'); // Max retry attempts reached
    }

    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
    };

    const loadCurrentImages = async () => {
        // Embed current time to disable cache
        let response = await fetch(
            'https://images.puzzle-technology.com/homepageimages/data.json'
        );
        const jsonData = await response.json();
        setSlides(jsonData.images)
    };


    useEffect(() => {
        loadCurrentImages();
    }, [])

    useEffect(() => {
        setHomePageProducts(data.home.homePageProducts)
    }, [data])

    return (
        <>
            <Nav></Nav>
            <Header onSearchClick={handleSearchClick}></Header>

            <Helmet>
                <title>PuZzLe Technology Computer Store | PC Components </title>
                <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"></meta>
                <meta name='description' content="With over 10 years of expertise in computer components, we help you find the best products at the lowest prices."/>
                <meta name="keywords" content="PuZzLe Technology, puzzle tech, موقع بازل, موقع البازل, بازل تكنولوجي, محل بازل"></meta>
            </Helmet>

            <div className="w-[100%] max-w-[1400px] mx-auto">
                <Carousel slides={slides} />
            </div>

            {/* Scrollable Categories */}
            <div id="categories" className="relative">
                <div className="flex sm:flex-wrap sm:justify-center overflow-x-auto gap-2 lg:gap-3 2xl:gap-4 mt-10 px-mobile md:px-tablet xl:px-pc">
                    {
                        categories.map((ele, index) => (
                            <CategoryCard key={index} data={ele} className="flex-shrink-0 w-[calc(100%/2)] sm:w-[calc(100%/3)] lg:w-[calc(100%/4)] xl:w-[calc(100%/6)]" />
                        ))
                    }
                </div>

            </div>


            {/* Section: Why buy from us ? */}
            <section className="mt-10 px-mobile md:px-tablet xl:px-pc">
                <h1 className="text-gradient font-bold text-[24px] m-0 text-center font-['cairo']">Why buy from us?</h1>
                <div className="mx-auto w-[210px] bg-white h-[3px] rounded-full "></div>
                <p className="text-white font-medium leading-6  text-center mt-4 font-['cairo']">
                    With more than 10 years of experience in computer components, which helps us meet your needs in purchasing the best components at the lowest prices.
                </p>
            </section>


           {/* Section: Today Best Deals */}
           {
            homePageProducts.map((section,index)=>{
                return <section key={index} className="mt-10 px-mobile md:px-tablet xl:px-pc ">
                       <h2 className="text-white font-semibold text-[24px] m-0 font-['cairo'] mb-4">{section.section_name}</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2 lg:gap-3 2xl:gap-4">
                    {section.products.map((ele, index) => (
                        <ProductVertical key={index} data={ele} inHomePage={true} />
                    ))}
                </div>
    
                </section>
            })
           }
           {/* <section className="mt-10 px-mobile md:px-tablet xl:px-pc ">
                <h2 className="text-white font-semibold text-[24px] m-0 font-['cairo'] mb-4">Today’s Best Deals</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2 lg:gap-3 2xl:gap-4">
                    {todatBestDeals.slice(0, 12).map((ele, index) => (
                        <ProductVertical key={index} data={ele} inHomePage={true} />
                    ))}
                </div>
                {todatBestDeals.length > 12 && (
                    <Link to="/today-best-deals">
                        <span className="cursor-pointer w-fit mx-auto mt-4 flex items-center justify-center text-gradient font-['kensmark-03'] rounded-lg px-8 py-2 border-2 border-bw-02">
                            SHOW MORE
                        </span>
                    </Link>
                )}
            </section> */}

            {/* <section className="mt-10 px-mobile md:px-tablet xl:px-pc ">
                <h2 className="text-white font-semibold text-[24px] m-0 font-['cairo'] mb-4">Today’s Best Deals</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2 lg:gap-3 2xl:gap-4">
                    {todatBestDeals.slice(0, 12).map((ele, index) => (
                        <ProductVertical key={index} data={ele} inHomePage={true} />
                    ))}
                </div>
                {todatBestDeals.length > 12 && (
                    <Link to="/today-best-deals">
                        <span className="cursor-pointer w-fit mx-auto mt-4 flex items-center justify-center text-gradient font-['kensmark-03'] rounded-lg px-8 py-2 border-2 border-bw-02">
                            SHOW MORE
                        </span>
                    </Link>
                )}
            </section>
            <section className="mt-10 px-mobile md:px-tablet xl:px-pc">
                <h2 className="text-white font-semibold text-[24px] m-0 font-['cairo'] mb-4">Featured Savings</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2 lg:gap-3 2xl:gap-4">
                    {featuredSavings.slice(0, 12).map((ele, index) => (
                        <ProductVertical key={index} data={ele} />
                    ))}
                </div>
                {featuredSavings.length > 12 && (
                    <Link to="/featured-savings">
                        <span className="cursor-pointer w-fit mx-auto mt-4 flex items-center justify-center text-gradient font-['kensmark-03'] rounded-lg px-8 py-2 border-2 border-bw-02">
                            SHOW MORE
                        </span>
                    </Link>
                )}
            </section>

            <section className="mt-10 px-mobile md:px-tablet xl:px-pc">
                <h2 className="text-white font-semibold text-[24px] m-0 font-['cairo'] mb-4">Popular Products</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2 lg:gap-3 2xl:gap-4">
                    {popularProducts.slice(0, 12).map((ele, index) => (
                        <ProductVertical key={index} data={ele} />
                    ))}
                </div>
                {popularProducts.length > 12 && (
                    <Link to="/popular-products">
                        <span className="cursor-pointer w-fit mx-auto mt-4 flex items-center justify-center text-gradient font-['kensmark-03'] rounded-lg px-8 py-2 border-2 border-bw-02">
                            SHOW MORE
                        </span>
                    </Link>
                )}
            </section> */}


            <BottomNav currentPage={""}></BottomNav>
            <Footer></Footer>
            {loading ?
                <Loader></Loader>
                : null
            }
            {
                isSearching ?
                    <>
                        <SearchInput></SearchInput>
                        <div onClick={() => setIsSearching(false)}>
                            <Overlay></Overlay>
                        </div>
                    </> : null
            }
                        {/* <TawkMessengerReact
                propertyId={process.env.REACT_APP_CHAT_PROPERTY_ID}
                widgetId={process.env.REACT_APP_CHAT_WIDGET_ID} /> */}
            <ToastContainer></ToastContainer>
            <Cart></Cart>
            <MobileMenu></MobileMenu>

        </>
    )
}

export default HomePage;