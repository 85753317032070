import BreadCrump from "../components/BreadCrump.component";
import Nav from "../components/Nav";
import SearchInput from "../components/search-input.component";
import Overlay from "../components/Overlay";
import MobileMenu from "../components/menu.component";
import BottomNav from "../components/bottom-nav.component";
import { useState } from "react";
import Cart from "../components/cart.compontent";
import Header from "../components/Header";
import Footer from "../components/footer.component";
import categories from "../statics/nav-items";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


function CategoriesPage () {
    const breadcrumbItems = [{name: "HOME", to: "/"}, {"name": "categories", "to": "/categories"}]

    const [isSearching, setIsSearching] = useState(false);

    
    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
    };

    return (
      
        <>
            <Nav></Nav>
            <Header onSearchClick={handleSearchClick}></Header>
            <BreadCrump items={breadcrumbItems} />
            <Helmet>
                <title>PuZzLe Technology | Product Categories</title>
                <meta name="description" content="Discover a variety of categories where you'll find everything you need, from top-quality products to unbeatable prices. Explore now!"/>         
                   </Helmet>
                   
            
        {categories.map((category, index) => (
          <div key={index} className="group ">
            <Link
              to={``}
              className="text-white text-center flex items-center font-['kensmark-03'] hover:text-gradient"
            >
              {/* {category.tag} */}
            </Link>
            <div className="duration-300 ease-in-out group-hover:flex group-hover:flex-col text-white rounded-lg  font-['kensmark-03']">
              <div className="py-2"></div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4  p-4">
                <ul className="flex-1">
                  {category.items
                    .slice(0, Math.ceil(category.items.length / 4))
                    .map((item, idx) => (
                      <li key={idx} className="px-4 py-2 hover:bg-bw-03">
                        <Link to={`/category/${item.slug}`} className="flex gap-2">
                          <img src={item.icon} className="w-6 h-6" alt="icon" />
                          {item.name}
                        </Link>
                        {item.children && (
                          <ul className="mt-2 ml-4">
                            {item.children.map((child, childIdx) => (
                              <li
                                key={childIdx}
                                className="px-4 py-2 hover:bg-black"
                              >
                                <Link to={`/category${child.slug}`}>{child.name}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                </ul>
                <ul className="flex-1">
                  {category.items
                    .slice(
                      Math.ceil(category.items.length / 4),
                      Math.ceil(category.items.length / 2)
                    )
                    .map((item, idx) => (
                      <li key={idx} className="px-4 py-2 hover:bg-bw-03">
                        <Link to={`/category/${item.slug}`} className="flex gap-2">
                          <img src={item.icon} className="w-6 h-6" alt="icon" />
                          {item.name}
                        </Link>
                        {item.children && (
                          <ul className="mt-2 ml-4">
                            {item.children.map((child, childIdx) => (
                              <li
                                key={childIdx}
                                className="px-4 py-2 hover:bg-black"
                              >
                                <Link to={`/category${child.slug}`}>{child.name}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                </ul>
                <ul className="flex-1">
                  {category.items
                    .slice(
                      Math.ceil(category.items.length / 2),
                      Math.ceil((3 * category.items.length) / 4)
                    )
                    .map((item, idx) => (
                      <li key={idx} className="px-4 py-2 hover:bg-bw-03">
                        <Link to={`/category/${item.slug}`} className="flex gap-2">
                          <img src={item.icon} className="w-6 h-6" alt="icon" />
                          {item.name}
                        </Link>
                        {item.children && (
                          <ul className="mt-2 ml-4">
                            {item.children.map((child, childIdx) => (
                              <li
                                key={childIdx}
                                className="px-4 py-2 hover:bg-black"
                              >
                                <Link to={`/category${child.slug}`}>{child.name}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                </ul>
                <ul className="flex-1">
                  {category.items
                    .slice(Math.ceil((3 * category.items.length) / 4))
                    .map((item, idx) => (
                      <li key={idx} className="px-4 py-2 hover:bg-bw-03">
                        <Link to={`/category/${item.slug}`} className="flex gap-2">
                          <img src={item.icon} className="w-6 h-6" alt="icon" />
                          {item.name}
                        </Link>
                        {item.children && (
                          <ul className="mt-2 ml-4">
                            {item.children.map((child, childIdx) => (
                              <li
                                key={childIdx}
                                className="px-4 py-2 hover:bg-black"
                              >
                                <Link to={`/category${child.slug}`}>{child.name}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        ))}

      
            {
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }

            <Footer></Footer>
            <Cart></Cart>
            <MobileMenu></MobileMenu>
            <BottomNav currentPage={"/categories"}></BottomNav>

        </>
    )
}


export default CategoriesPage;